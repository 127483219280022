@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  /*border: 1px solid black; */
}

@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}

a {
  font-family: "Inter", sans-serif;
}


body::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none;
}

html, body, #root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
 }

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

footer {
  bottom: 0%;
  margin-top: auto
}

.hero {
  /* position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); */
  text-align: center;
  /* clear: both; */
}

.title {
  font-family: "Inter", sans-serif;
  font-size: calc(70px + (200 - 70) * ((100vw - 576px) / (2560 - 576)));
  font-weight: 700;
  letter-spacing: -1px;
}

/* @media only screen and (max-width: 400px) {
  .title {
    font-size: calc(50px + (60 - 40) * ((100vw - 576px) / (2560 - 576)));
  }
} */

.sub-head {
  font-family: "EB Garamond", serif;
  font-style: italic;
  font-weight: 500;
}

.p5-container {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  /* margin-top: 5em;
  margin-bottom: 5em; */
  transform: translate(0, 30%);
}

.list-item {
  margin-bottom: 1em;
}

/*Text Styling*/

.intro {
  font-family: "Inter", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.intro.header {
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
}

.intro.body {
  font-size: 2em;
  font-weight: 600;
  line-height: 1.3em;
}

.expl {
  font-family: "EB Garamond", serif;
}

.expl.header {
  font-family: "Inter", sans-serif;
  font-size: 1.8em;
  font-weight: 500;
  padding-top: 2.5em;
}

.expl.body {
  font-size: 1.5em;
  font-weight: 500;
  color: #444;
}

button:hover{
cursor: pointer;
}

/*Methodology > Button Styling*/

.method-button {
  font-family: "Inter", sans-serif;
  background: none;
  border-radius: 2em;
  padding: 0.5em 1em 0.5em 1em;
  border: 1px solid;
}

@media only screen and (max-width: 750px) {
  .method-button {
    font-size: 0.9em;
    margin: auto;
  }
}
